import { LoanApplicationUniqueStepNames } from '@oper-client/shared/data-model';
import { LoanApplicationFeatureConfiguration } from '../../interface/loan-application-feature.interface';

const featureConfiguration: LoanApplicationFeatureConfiguration = {
	steps: [
		{
			name: 'aboutProject',
			title: 'ç.feature.selfService.steps.aboutProject.title',
			steps: [
				{
					name: 'aboutProject.projectDetails',
					title: 'ç.feature.selfService.steps.projectDetails.title',
					description: 'ç.feature.selfService.steps.projectDetails.description',
					formName: 'loanApplicationProjectDetails',
					next: 'aboutProject.yourContribution',
					nextLabel: 'ç.misc.action.next',
				},
				{
					name: 'aboutProject.yourContribution',
					title: 'ç.feature.selfService.steps.yourContribution.title',
					description: 'ç.feature.selfService.steps.yourContribution.description',
					formName: 'loanApplicationYourContribution',
					next: 'personalDetails',
					nextLabel: 'ç.misc.action.next',
					back: 'aboutProject.projectDetails',
					backLabel: 'ç.misc.action.back',
				},
			],
		},
		{
			name: 'personalDetails',
			title: 'ç.feature.selfService.steps.personalDetails.title',
			description: 'ç.feature.selfService.steps.personalDetails.description',
			formName: 'loanApplicationPersonalDetails',
			back: 'aboutProject.yourContribution',
			backLabel: 'ç.misc.action.back',
			next: LoanApplicationUniqueStepNames.SUBMIT_APPLICATION,
			nextLabel: 'ç.misc.action.next',
		},
		{
			name: LoanApplicationUniqueStepNames.SUBMIT_APPLICATION,
			title: 'ç.feature.selfService.steps.submitApplication.title',
			description: 'ç.feature.selfService.steps.submitApplication.description',
			formName: 'loanApplicationSubmitApplication',
			back: 'personalDetails',
			backLabel: 'ç.misc.action.back',
			next: 'submit',
			nextLabel: 'ç.misc.action.submit',
		},
	],
};

export default featureConfiguration;
