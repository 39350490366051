<div class="header">
	<h3 class="title">
		{{ 'ç.feature.mortgageSimulator.result.bulletLoan.title' | translate }}
	</h3>
	@if (isBulletLoanAdded()) {
		<button class="button-tertiary" (click)="onRemoveBulletLoan()">
			{{ 'ç.feature.mortgageSimulator.result.bulletLoan.removeLoan' | translate }}
		</button>
	} @else {
		<button class="button-tertiary" (click)="onAddBulletLoan()">
			{{ 'ç.feature.mortgageSimulator.result.bulletLoan.addLoan' | translate }}
		</button>
	}
</div>
