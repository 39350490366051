import { StepNames } from './application.model';
import { Signal } from '@angular/core';

export interface Step {
	name: StepNames | string;
	title?: string;
	description?: string;
	disclaimer?: string | false;
	required?: boolean;
	back?: StepNames | string;
	backLabel?: string;
	next?: StepNames | string;
	nextLabel?: string;
	skipSimulation?: boolean;
	hideInStepper?: boolean;
	recaulculateFormName?: string;
	formName?: string;
	skip?: boolean;
	skipFormValidation?: boolean;
	completed?: boolean;
	active?: boolean;
	disabled?: boolean;
	tooltip?: string;
	steps?: Step[];
}

export interface StepError {
	error: string;
	step: Step;
	index: number;
}

export class StepValidationResult {
	valid: boolean;
	errors: StepError[] = [];

	constructor(valid = true) {
		this.valid = valid;
	}
}

export interface StepperConfiguration {
	steps: Step[];
}

export interface StepData<T> {
	step: string;
	data: Partial<T>;
}

export interface IStepperService {
	getConfiguration?(): Signal<StepperConfiguration>;
	getActiveStep(): Signal<Step>;
	getSteps(): Signal<Step[]>;
	getCompletedStepNames(): Signal<string[]>;
	setActiveStep(step: Step): void;
	setConfiguration(configuration: StepperConfiguration): void;
	setDataForStep<T>(stepData: StepData<T>): void;
	setData?<T>(data: Partial<T>): void;
	updateData?<T>(data: Partial<T>): void;
	updateConfiguration(changes: Partial<StepperConfiguration>): void;
	updateActiveStep(changes: Partial<Step>): void;
	nextStep(): void;
	previousStep(): void;
	reset(): void;
	clearData(): void;
}
