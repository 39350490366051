import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { AmountAllocation } from '@oper-client/shared/data-model';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'oper-client-simulation-report-bullet-loan',
	standalone: true,
	imports: [TranslateModule],
	templateUrl: './simulation-report-bullet-loan.component.html',
	styleUrl: './simulation-report-bullet-loan.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportBulletLoanComponent {
	readonly totalLoanAmount = input<number>(0);
	readonly allocations = input<AmountAllocation[]>([]);
	readonly isBulletLoanAdded = input.required<boolean>();
	readonly removeBulletLoan = output<void>();
	readonly addBulletLoan = output<void>();

	onAddBulletLoan(): void {
		this.addBulletLoan.emit();
	}

	onRemoveBulletLoan(): void {
		this.removeBulletLoan.emit();
	}
}
