import {
	ActionState,
	initialActionState,
	Step,
	findStepByName,
	AnyObject,
	ActionTypes,
	setActionState,
	LoanApplicationDto,
	mapToLeft,
	LoanApplicationDtoToLoanRequestMapper,
	LoanApplicationDtoToSimulationMapper,
	LoanApplicationDtoToOfferMapper,
	LoanApplicationDtoToRealtyMapper,
	generateDataObjectFromStepDataPoints,
	LoanApplicationDtoToClientsMapper,
	LoanApplicationDtoToIncomesMapper,
	LoanApplicationDtoToLiabilitiesMapper,
	LoanApplicationDtoToCollateralRealtyMapper,
	RealtyPurposeEnum,
} from '@oper-client/shared/data-model';
import { createRehydrateReducer } from '@oper-client/shared/util-client-storage';
import * as LoanApplicationActions from './loan-application.actions';
import { on } from '@ngrx/store';
import { LoanApplicationFeatureConfiguration } from '../../interface/loan-application-feature.interface';
import { HttpErrorResponse } from '@angular/common/http';

export const LOAN_APPLICATION_KEY = 'loanApplication';

export type LoanApplicationActionTypes =
	| 'loadLoanRequest'
	| 'loadSimulation'
	| 'updateLoanRequest'
	| 'loadOffers'
	| 'loadClients'
	| 'loadRealties'
	| 'loadClientIncomes'
	| 'loadClientLiabilities'
	| 'updateClient'
	| 'updateRealty'
	| 'updateCollateralRealty'
	| 'createLoanRequestComment';
export type LoanApplicationActionState = Record<LoanApplicationActionTypes, ActionState>;

export interface LoanApplicationState {
	configuration: LoanApplicationFeatureConfiguration | null;
	activeStep: Step | null;
	dataPoints: AnyObject<Partial<LoanApplicationDto>> | null;
	data: LoanApplicationDto | null;
	actions: LoanApplicationActionState;
}

export const initialState: LoanApplicationState = {
	configuration: null,
	dataPoints: null,
	activeStep: null,
	data: null,
	actions: {
		loadLoanRequest: initialActionState,
		loadSimulation: initialActionState,
		loadOffers: initialActionState,
		loadClients: initialActionState,
		loadRealties: initialActionState,
		loadClientIncomes: initialActionState,
		loadClientLiabilities: initialActionState,
		updateLoanRequest: initialActionState,
		updateClient: initialActionState,
		updateRealty: initialActionState,
		updateCollateralRealty: initialActionState,
		createLoanRequestComment: initialActionState,
	},
};

function setActionStates(
	actionState: LoanApplicationActionState,
	action: LoanApplicationActionTypes,
	actionType: ActionTypes,
	error: HttpErrorResponse = null,
	ids?: number[]
): LoanApplicationActionState {
	return {
		...initialState.actions,
		[action]: setActionState(actionState[action], actionType, error, ids),
	};
}

export const reducer = createRehydrateReducer(
	LOAN_APPLICATION_KEY,
	initialState,
	on(LoanApplicationActions.loadLoanRequest, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequest', ActionTypes.loading),
	})),
	on(LoanApplicationActions.loadLoanRequestSuccess, (state, { loanRequest }) => ({
		...state,
		data: { ...state.data, ...mapToLeft(loanRequest, new LoanApplicationDtoToLoanRequestMapper()) },
		actions: setActionStates(state.actions, 'loadLoanRequest', ActionTypes.success),
	})),
	on(LoanApplicationActions.loadLoanRequestFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadLoanRequest', ActionTypes.failure),
	})),
	on(LoanApplicationActions.loadSimulation, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadSimulation', ActionTypes.loading),
	})),
	on(LoanApplicationActions.loadSimulationSuccess, (state, { simulation }) => ({
		...state,
		data: { ...state.data, simulation, ...mapToLeft(simulation, new LoanApplicationDtoToSimulationMapper()) },
		actions: setActionStates(state.actions, 'loadSimulation', ActionTypes.success),
	})),
	on(LoanApplicationActions.loadSimulationFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadSimulation', ActionTypes.failure),
	})),
	on(LoanApplicationActions.updateLoanRequest, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLoanRequest', ActionTypes.loading),
	})),
	on(LoanApplicationActions.updateLoanRequestSuccess, (state, { loanRequest }) => ({
		...state,
		data: { ...state.data, ...mapToLeft(loanRequest, new LoanApplicationDtoToLoanRequestMapper()) },
		actions: setActionStates(state.actions, 'updateLoanRequest', ActionTypes.success),
	})),
	on(LoanApplicationActions.updateLoanRequestFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateLoanRequest', ActionTypes.failure),
	})),
	on(LoanApplicationActions.loadOffers, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.loading),
	})),
	on(LoanApplicationActions.loadOffersSuccess, (state, { offers }) => ({
		...state,
		data: { ...state.data, ...mapToLeft(offers?.[0], new LoanApplicationDtoToOfferMapper()) },
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.success),
	})),
	on(LoanApplicationActions.loadOffersFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadOffers', ActionTypes.failure),
	})),
	on(LoanApplicationActions.loadClients, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClients', ActionTypes.loading),
	})),
	on(LoanApplicationActions.loadClientsSuccess, (state, { clients }) => ({
		...state,
		data: { ...state.data, ...mapToLeft(clients, new LoanApplicationDtoToClientsMapper()) },
		actions: setActionStates(
			state.actions,
			'loadClients',
			ActionTypes.success,
			null,
			clients.map((c) => c.id)
		),
	})),
	on(LoanApplicationActions.loadClientsFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClients', ActionTypes.failure),
	})),
	on(LoanApplicationActions.loadClientIncomes, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClientIncomes', ActionTypes.loading),
	})),
	on(LoanApplicationActions.loadClientIncomesSuccess, (state, { incomes }) => ({
		...state,
		data: { ...state.data, ...mapToLeft(incomes, new LoanApplicationDtoToIncomesMapper(state.data)) },
		actions: setActionStates(state.actions, 'loadClientIncomes', ActionTypes.success),
	})),
	on(LoanApplicationActions.loadClientIncomesFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClientIncomes', ActionTypes.failure),
	})),
	on(LoanApplicationActions.loadClientLiabilities, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClientLiabilities', ActionTypes.loading),
	})),
	on(LoanApplicationActions.loadClientLiabilitiesSuccess, (state, { liabilities }) => ({
		...state,
		data: { ...state.data, ...mapToLeft(liabilities, new LoanApplicationDtoToLiabilitiesMapper()) },
		actions: setActionStates(state.actions, 'loadClientLiabilities', ActionTypes.success),
	})),
	on(LoanApplicationActions.loadClientLiabilitiesFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadClientLiabilities', ActionTypes.failure),
	})),
	on(LoanApplicationActions.loadRealties, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadRealties', ActionTypes.loading),
	})),
	on(LoanApplicationActions.loadRealtiesSuccess, (state, { realties }) => ({
		...state,
		data: {
			...state.data,
			...mapToLeft(
				realties?.find((realty) => !realty.purposes.some((purpose) => purpose.definition === RealtyPurposeEnum.COLLATERAL)),
				new LoanApplicationDtoToRealtyMapper()
			),
			...mapToLeft(
				realties?.find((realty) => realty.purposes.some((purpose) => purpose.definition === RealtyPurposeEnum.COLLATERAL)),
				new LoanApplicationDtoToCollateralRealtyMapper()
			),
		},
		actions: setActionStates(state.actions, 'loadRealties', ActionTypes.success),
	})),
	on(LoanApplicationActions.loadRealtiesFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'loadRealties', ActionTypes.failure),
	})),
	on(LoanApplicationActions.updateRealty, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateRealty', ActionTypes.loading),
	})),
	on(LoanApplicationActions.updateRealtySuccess, (state, { realty }) => ({
		...state,
		data: { ...state.data, ...mapToLeft(realty, new LoanApplicationDtoToRealtyMapper()) },
		actions: setActionStates(state.actions, 'updateRealty', ActionTypes.success),
	})),
	on(LoanApplicationActions.updateRealtyFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateRealty', ActionTypes.failure),
	})),
	on(LoanApplicationActions.updateCollateralRealty, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateCollateralRealty', ActionTypes.loading),
	})),
	on(LoanApplicationActions.updateCollateralRealtySuccess, (state, { realty }) => ({
		...state,
		data: { ...state.data, ...mapToLeft(realty, new LoanApplicationDtoToCollateralRealtyMapper()) },
		actions: setActionStates(state.actions, 'updateCollateralRealty', ActionTypes.success),
	})),
	on(LoanApplicationActions.updateCollateralRealtyFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateCollateralRealty', ActionTypes.failure),
	})),
	on(LoanApplicationActions.createLoanRequestComment, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createLoanRequestComment', ActionTypes.loading),
	})),
	on(LoanApplicationActions.createLoanRequestCommentSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createLoanRequestComment', ActionTypes.success),
	})),
	on(LoanApplicationActions.createLoanRequestCommentFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'createLoanRequestComment', ActionTypes.failure),
	})),
	on(LoanApplicationActions.updateClient, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateClient', ActionTypes.loading),
	})),
	on(LoanApplicationActions.updateClientSuccess, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateClient', ActionTypes.success),
	})),
	on(LoanApplicationActions.updateClientFailure, (state) => ({
		...state,
		actions: setActionStates(state.actions, 'updateClient', ActionTypes.failure),
	})),
	on(LoanApplicationActions.setConfiguration, (state, { configuration }) => ({
		...state,
		configuration,
	})),
	on(LoanApplicationActions.updateConfiguration, (state, { changes }) => ({
		...state,
		configuration: { ...state.configuration, ...changes },
	})),
	on(LoanApplicationActions.setActiveStep, (state, { step }) => ({
		...state,
		activeStep: step,
	})),
	on(LoanApplicationActions.updateActiveStep, (state, { changes }) => ({
		...state,
		activeStep: { ...state.activeStep, ...changes },
	})),
	on(LoanApplicationActions.nextStep, (state) => ({
		...state,
		activeStep: findStepByName(state.configuration.steps, state.activeStep?.next),
	})),
	on(LoanApplicationActions.prevStep, (state) => ({
		...state,
		activeStep: findStepByName(state.configuration.steps, state.activeStep?.back),
	})),
	on(LoanApplicationActions.setDataForStep, (state, { step, data }) => {
		const dataPoints = { ...state.dataPoints, [step]: data };
		const dataObject = generateDataObjectFromStepDataPoints<Partial<LoanApplicationDto>>(dataPoints);

		return {
			...state,
			dataPoints,
			data: { ...state.data, ...dataObject },
		};
	}),
	on(LoanApplicationActions.reset, () => ({
		...initialState,
	})),
	on(LoanApplicationActions.clearData, (state) => ({
		...state,
		data: initialState.data,
		dataPoints: initialState.dataPoints,
		actions: initialState.actions,
	}))
);
