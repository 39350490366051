import { ChangeDetectionStrategy, Component, Inject, OnInit, signal } from '@angular/core';
import { AUTH_SERVICE, IAuthService } from '@oper-client/shared/data-access';
import { FeatureFlag, IAM, ResourceType } from '@oper-client/shared/data-model';
import { LanguageService, LocaleService } from '@oper-client/shared/util-language';
import {
	AppFacade,
	DeviceDetectionService,
	OnlineDetectionService,
	VisibilityDetectionService,
} from '@oper-client/shared/app/data-access-app';
import { environment } from '../environments/environment';
import { combineLatestWith, distinctUntilChanged, filter, map, Observable, of, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { AppointmentBookingService } from '@oper-client/shared/api-v2.0';
import { LocalStorageService } from '@oper-client/shared/util-client-storage';
import { LinkFacade } from '@oper-client/shared/link/data-access-link';
import { ResourcesFacade } from '@oper-client/shared/resources/data-access-resource';
import { NavigationEnd, Router } from '@angular/router';
import { FeatureFlagService } from '@oper-client/shared/util-feature-flag';
import { IamFacade } from '@oper-client/shared/iam/data-access-iam';
import { DestroyableComponent } from '@shared/util-component';
import { GtmEventNameEnum, GtmFacade } from '@oper-client/shared/tracking/gtm';
import { GoogleLibrariesService } from '@oper-client/shared/util-google-maps';
import { LoanRequestFacade } from '@oper-client/shared/loan-request/data-access-loan-request';

@Component({
	selector: 'oper-client-self-service-root',
	styleUrls: ['./app.component.scss'],
	templateUrl: './app.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent extends DestroyableComponent implements OnInit {
	readonly $isHamburgerMenuShown = signal<boolean>(false);
	readonly $isLangageSelectorExpanded = signal<boolean>(false);
	readonly $isNavbarVisible = signal(true);
	readonly $currentLanguage = signal<string>(this.languageService.getCurrentLanguage());
	readonly languageChanged$ = new Subject<string>();
	readonly newDeploymentDetected$: Observable<boolean>;
	readonly languages$ = this.resourceFacade.getResourceByType(ResourceType.LANGUAGE);
	readonly showLanguageSelector$ = this.languages$.pipe(map((languages) => languages.length > 1));
	readonly contactsEnabled$ = this.featureFlagService.hasFeatureFlag(FeatureFlag.SELF_SERVICE_CONTACT_SCREEN);
	readonly simulatorEnabled$ = this.featureFlagService.hasFeatureFlag(FeatureFlag.SELF_SERVICE_MORTGAGE_SIMULATOR);
	readonly shouldShowStartUploadingDocumentsMenuItemTooltip$: Observable<boolean> = this.featureFlagService
		.hasFeatureFlag(FeatureFlag.SELF_SERVICE_LOAN_APPLICATION_FLOW_SELF_APPLICATION)
		.pipe(
			combineLatestWith(this.loanRequestFacade.loanRequestList$),
			map(([flag, loanRequests]) => !!flag && !loanRequests?.length)
		);
	readonly simulatorNewVersionEnabled$ = this.featureFlagService.hasFeatureFlag(
		FeatureFlag.SELF_SERVICE_BORROWER_SIMULATOR_BELGIUM_NEW_VERSION
	);
	readonly documentsEnabled$ = this.featureFlagService.hasFeatureFlag(FeatureFlag.SELF_SERVICE_DOCUMENTS);
	readonly localeDirection$ = this.localeService.getLocaleDirection$();
	readonly userVerified$ = this.iamFacade.user$.pipe(map((user) => !!user?.verified));

	readonly isSignupRouteShown$ = this.router.events.pipe(
		filter((event) => event instanceof NavigationEnd),
		map(() => this.router.url.includes('sign-up')),
		distinctUntilChanged()
	);

	constructor(
		@Inject(AUTH_SERVICE) readonly auth: IAuthService,
		private readonly languageService: LanguageService,
		private readonly appFacade: AppFacade,
		private readonly resourceFacade: ResourcesFacade,
		private readonly visibilityDetectionService: VisibilityDetectionService,
		private readonly deviceDetectionService: DeviceDetectionService,
		private readonly onlineDetectionService: OnlineDetectionService,
		private readonly appointmentBookingService: AppointmentBookingService,
		private readonly loanRequestFacade: LoanRequestFacade,
		private readonly localStorageService: LocalStorageService,
		private readonly localeService: LocaleService,
		private readonly featureFlagService: FeatureFlagService,
		private readonly linkFacade: LinkFacade,
		private readonly iamFacade: IamFacade,
		private readonly gtmFacade: GtmFacade,
		private readonly router: Router,
		private readonly googleLibrariesService: GoogleLibrariesService
	) {
		super();
		this.languageService.init();
		this.newDeploymentDetected$ = environment.production ? this.appFacade.newDeploymentDetected$ : of(false);
		this.visibilityDetectionService.init();
		this.deviceDetectionService.init();
		this.onlineDetectionService.init();
		this.appointmentBookingService.setBookingUrl();
		this.localStorageService.set('jbNavToggle', 'true');
		this.linkFacade.loadLinks();
	}

	ngOnInit(): void {
		this.auth
			.getCurrentUser()
			.pipe(takeUntil(this.destroy$))
			.subscribe((user: IAM.User) => {
				this.languageService.init(user);
			});

		this.languageChanged$.pipe(withLatestFrom(this.languages$), takeUntil(this.destroy$)).subscribe(([language, languages]) => {
			this.languageService.changeLanguage(language, languages);
			this.$isLangageSelectorExpanded.set(false);
			this.$isHamburgerMenuShown.set(false);
		});

		this.languageService.languageChanged$.pipe(takeUntil(this.destroy$)).subscribe((language) => {
			this.$currentLanguage.set(language);
		});

		this.googleLibrariesService.injectLibrariesScript();
	}

	navigateTo(url: string): void {
		this.router.navigateByUrl(url);
	}

	logout(): void {
		this.iamFacade.logout();
		this.navigateTo('/auth');
		this.gtmFacade.sendEvent(GtmEventNameEnum.AUTH_SIGN_OUT);
	}

	onMenuItemClick(): void {
		this.$isHamburgerMenuShown.set(false);
	}

	toggleHamburgerMenu(): void {
		this.$isHamburgerMenuShown.set(!this.$isHamburgerMenuShown());
	}
}
