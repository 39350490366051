import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { UiModule } from '../../ui.module';
import { TranslateModule } from '@ngx-translate/core';
import { BaseProductDiscounts, DiscountConfiguration } from '@oper-client/shared/data-model';

interface SelectableDiscount {
	baseProductDiscount: BaseProductDiscounts;
	config: DiscountConfiguration[];
}

@Component({
	selector: 'oper-client-simulation-report-discounts-applied',
	standalone: true,
	imports: [UiModule, TranslateModule],
	templateUrl: './simulation-report-discounts-applied.component.html',
	styleUrl: './simulation-report-discounts-applied.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportDiscountsAppliedComponent {
	readonly allDiscounts = input.required<Partial<BaseProductDiscounts>[] | null>();
	readonly selectedDiscounts = input.required<BaseProductDiscounts[] | null>();
	readonly selectedDiscountsChange = output<BaseProductDiscounts[]>();

	readonly shownDiscounts = computed(() => {
		return this.allDiscounts().map((baseProductDiscount) => {
			const config = baseProductDiscount.discounts;
			return { baseProductDiscount, config } as SelectableDiscount;
		});
	});
}
