import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { AmountRatioBarComponent } from '../amount-ratio-bar/amount-ratio-bar.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'oper-client-simulation-report-mortgage-overview',
	standalone: true,
	imports: [AmountRatioBarComponent, TranslateModule],
	templateUrl: './simulation-report-mortgage-overview.component.html',
	styleUrl: './simulation-report-mortgage-overview.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportMortgageOverviewComponent {
	readonly ownFunds = input<number>(0);
	readonly amountToBorrow = input<number>(0);
	readonly total = computed(() => this.ownFunds() + this.amountToBorrow());
}
