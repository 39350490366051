<oper-client-top-navigation-menu
	#navigationMenu
	[isLogoClickable]="!!logoRouterLink()"
	[logoRouterLink]="logoRouterLink()"
	(hamburgerMenuIconClick)="hamburgerMenuClick.emit()"
	(menuItemClick)="menuItemClick.emit($event)"
>
	@if (isAuthenticated()) {
		<ng-template
			operClientTopNavigationMenuItem
			[label]="'ç.feature.selfService.navigation.dashboard.label' | translate"
			[route]="['/app/dashboard']"
			[icon]="'faBriefcase'"
			[order]="1"
			[isShown]="isUserVerified()"
		/>

		<ng-template
			operClientTopNavigationMenuItem
			[label]="'ç.feature.selfService.navigation.documents.label' | translate"
			[route]="['/app/documents']"
			[icon]="'faFolderOpen'"
			[order]="2"
			[tooltipText]="
				shouldShowStartUploadingDocumentsMenuItemTooltip
					? ('ç.feature.selfService.navigation.documents.startUploadTooltip' | translate)
					: undefined
			"
			[isShown]="isDocumentsFeatureShown && isUserVerified()"
		/>

		<ng-template
			operClientTopNavigationMenuItem
			[label]="'ç.feature.selfService.navigation.contact.label' | translate"
			[route]="['/app/contact']"
			[isShown]="isContactsFeatureShown"
			[icon]="'faPhone'"
			[order]="3"
		/>

		<ng-template
			operClientTopNavigationMenuItem
			[label]="'ç.feature.selfService.navigation.signOut.label' | translate"
			[icon]="'faRightFromBracket'"
			[order]="4"
			(clicked)="logoutClick.emit()"
		/>
	} @else {
		<ng-template
			operClientTopNavigationMenuItem
			[label]="'ç.feature.selfService.navigation.simulator.label' | translate"
			[route]="['/mortgage-simulator']"
			[icon]="'faChartPie'"
			[order]="1"
			[isShown]="isSimulatorFeatureShown()"
		/>

		<ng-template
			operClientTopNavigationMenuItem
			[label]="'ç.feature.selfService.navigation.simulator.label' | translate"
			[route]="['/simulation']"
			[icon]="'faChartPie'"
			[order]="1"
			[isShown]="isSimulatorNewVersionEnabled()"
		/>

		<ng-template
			operClientTopNavigationMenuItem
			[label]="'ç.feature.selfService.navigation.contact.label' | translate"
			[route]="['/contact']"
			[isShown]="isContactsFeatureShown"
			[icon]="'faPhone'"
			[order]="2"
		/>
	}

	<ng-template operClientTopNavigationMenuItem [showCustomTemplate]="true" [isShown]="isLanguageSelectorShown && !isHamburgerMenuShown">
		@if (hasMultipleMenuItems()) {
			<div class="divider" [style.order]="5"></div>
		}

		<oper-client-language-selector [style.order]="5" [inverted]="true" [isGray]="true" [languages]="languages" />
	</ng-template>
</oper-client-top-navigation-menu>

@if (isHamburgerMenuShown) {
	<!-- hamburger navigation menu for mobile or tablet device screen size -->
	<div class="hamburger-menu-items" outsideClick (outsideClick)="onOutsideHamburgerMenuClick()">
		@for (item of navigationMenu.menuItems; track $index) {
			@if (item.isShown) {
				<oper-client-top-navigation-menu-item
					[icon]="item.icon"
					[label]="item.label"
					[route]="item.route"
					[style.order]="item.order"
					(clicked)="onHamburgerMenuItemClick(item)"
				/>
			}
		}

		@if (isLanguageSelectorShown) {
			<!-- custom language selector which is visible on mobile or tablet devices -->
			<oper-client-collapsible
				class="language-selector-collapsible"
				[ngClass]="{ 'language-selector-collapsible__divider': hasMultipleMenuItems() }"
				[style.order]="5"
				[open]="isLanguageSelectorExpanded || !hasMultipleMenuItems()"
			>
				<ng-template operClientCollapsibleHeader [showArrow]="true">
					<oper-client-top-navigation-menu-item
						class="language-selector-item"
						[icon]="'faGlobe'"
						[highlight]="true"
						[label]="'ç.resource.language-name.' + currentLanguage | translate"
					/>
				</ng-template>

				<ng-template operClientCollapsibleContent>
					@for (language of languages; track $index) {
						@if (language.definition !== currentLanguage) {
							<oper-client-top-navigation-menu-item
								class="language-selector-item"
								[icon]="'faGlobe'"
								[label]="'ç.resource.language-name.' + language.definition | translate"
								(clicked)="languageChange.emit(language.definition)"
							/>
						}
					}
				</ng-template>
			</oper-client-collapsible>
		}
	</div>
}
