import { DestroyRef, inject, Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import 'moment/min/locales';
import { BehaviorSubject } from 'rxjs';
import { LanguageService } from '@oper-client/shared/util-language';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Pipe({
	name: 'timeAgo',
	pure: false,
	standalone: true,
})
export class TimeAgoPipe implements PipeTransform {
	private _destroyRef = inject(DestroyRef);
	private _languageService = inject(LanguageService);

	/**
	 * We are using observables to update the time ago label when the language changes.
	 * @param {Date|string} value
	 * @returns {BehaviorSubject<string>}
	 */
	transform(value: Date | string): BehaviorSubject<string> {
		moment.locale(this._languageService.getCurrentLanguage());
		const momentSubject = new BehaviorSubject<string>(moment(value).fromNow());
		this._languageService.languageChanged$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((language) => {
			moment.locale(language);
			momentSubject.next(moment(value).fromNow());
		});
		return momentSubject;
	}
}
