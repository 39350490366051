import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UtilFormattingModule } from '@oper-client/shared/util-formatting';

@Component({
	selector: 'oper-client-amount-ratio-bar',
	templateUrl: './amount-ratio-bar.component.html',
	styleUrls: ['./amount-ratio-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [UtilFormattingModule],
})
export class AmountRatioBarComponent {
	@Input() color = '#000';
	@Input() startLabel = '';
	@Input() endLabel = '';
	@Input() startValue = 0;
	@Input() endValue = 0;
	@Input() min = 0;
	@Input() total = 0;

	calculateProgress(total: number, used: number): number {
		if (total > 0) {
			const progress = (used / total) * 100;
			if (progress > this.min) {
				return progress;
			}
		}

		return this.min;
	}
}
