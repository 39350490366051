<h3 class="title">{{ 'ç.feature.mortgageSimulator.report.overview' | translate }}</h3>
<div class="overview">
	<oper-client-amount-ratio-bar
		[startLabel]="'ç.question.ownFunds.label' | translate"
		[endLabel]="'ç.question.amountToBorrow.label' | translate"
		[startValue]="ownFunds()"
		[endValue]="amountToBorrow()"
		[total]="total()"
	/>
</div>

<ng-content></ng-content>
