import { Directive, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import * as Icons from '@oper-client/shared/util-fontawesome';
import { NavigationMenuItem } from '../../interfaces/navigation-menu-item';

@Directive({
	selector: '[operClientTopNavigationMenuItem]',
})
export class TopNavigationMenuItemDirective {
	@Input() isShown = true;
	@Input() showCustomTemplate = false;
	@Input() order = 0;
	@Input() icon: `${keyof typeof Icons}` | null = null;
	@Input() label = 'ç.misc.undefined';
	@Input() route: string | any[] | null = null;
	@Input() highlight = false;
	@Input() tooltipText: string | undefined;
	@Output() clicked = new EventEmitter<NavigationMenuItem>();

	getValue(): NavigationMenuItem {
		return {
			icon: this.icon,
			label: this.label,
			route: this.route,
		};
	}

	constructor(public templateRef: TemplateRef<any>) {}
}
