import { BorrowerSimulationDto, getLoanPurposesFromDto } from '../dto/borrower-simulation.dto';
import { Client } from '../models/client.model';
import { Simulator } from '../models/simulator.models';
import { FinancialPlanCalculator } from '../utils/financial-plan.utils';
import { MapperStrategy } from '../utils/mapper.utils';

export class SimulationToBorrowerSimulationDtoMapper
	implements MapperStrategy<Partial<Simulator.Simulation>, Partial<BorrowerSimulationDto>>
{
	mapToRight(left: Partial<Simulator.Simulation>): Partial<BorrowerSimulationDto> {
		const financialPlanCalculator = new FinancialPlanCalculator(left.financialPlanLoanAmountItems, left.financialPlanOwnFundItems);
		return {
			realtyType: left.realtyType,
			region: left.region,
			realtyUsageType: left.realtyUsageType,
			epcBeforeRenovations: left.epcBeforeRenovations,
			epcAfterRenovations: left.epcAfterRenovations,
			priceBuilding: left.buildingCosts,
			purchaseSaleType: left.purchaseSaleType,
			isBuyingLand: left.priceOfLand > 0,
			priceOfLand: left.priceOfLand,
			buyOutAmount: left.buyOutAmount,
			realtyPrice: left.realtyPrice,
			venalValueBefore: left.venalValueBefore,
			venalValueAfter: left.venalValueAfter,
			architectFees: left.architectFees,
			landPurchaseType: left.landPurchaseType,
			loanPurpose: left.loanPurposes?.[0],
			duration: left.monthlyPaymentEstimations?.[0]?.duration,
			interestRateOnLoan: left.monthlyPaymentEstimations?.[0]?.rate,
			ownFunds: financialPlanCalculator.mainOwnFundItem?.amount,
			totalLoanAmountAtBeginning: financialPlanCalculator.mainLoanAmountItem?.amount,
			renovationCost: left.renovationCosts?.[0],
			mainBorrowerIncomes: left.borrowersInfo?.[0]?.incomes,
			mainBorrowerLiabilities: left.borrowersInfo?.[0]?.liabilities,
			mainBorrowerPersonalDetails: left.borrowersInfo?.[0],
			coBorrowerIncomes: left.borrowersInfo?.[1]?.incomes,
			coBorrowerLiabilities: left.borrowersInfo?.[1]?.liabilities,
			coBorrowerPersonalDetails: left.borrowersInfo?.[1],
			extraCollateral: left.collaterals?.[0],
		};
	}

	mapToLeft(right: Partial<BorrowerSimulationDto>): Partial<Simulator.Simulation> {
		const calculator = new FinancialPlanCalculator(right.financialPlanLoanAmountItems, right.financialPlanOwnFundItems);
		const { financialPlanLoanAmountItems, financialPlanOwnFundItems } = calculator.updateMainOwnFundItem(right.ownFunds);

		return {
			name: `#${new Date().getTime()}`,
			loanPurposes: getLoanPurposesFromDto(right),
			realtyPrice: right.realtyPrice,
			region: right.region,
			priceOfLand: right.priceOfLand,
			venalValueBefore: right.venalValueBefore,
			venalValueAfter:
				right.venalValueAfter ?? (right.venalValueBefore ?? right.realtyPrice ?? 0) + (right.renovationCost?.amount ?? 0),
			buildingCosts: right.priceBuilding,
			epcBeforeRenovations: right.epcBeforeRenovations,
			epcAfterRenovations: right.epcAfterRenovations,
			buyOutAmount: right.buyOutAmount,
			realtyType: right.realtyType,
			realtyUsageType: right.realtyUsageType,
			purchaseSaleType: right.purchaseSaleType,
			architectFees: right.architectFees,
			landPurchaseType: right.landPurchaseType,
			borrowersInfo: getBorrowersInfo(right),
			renovationCosts: right.renovationCost ? [right.renovationCost] : undefined,
			financialPlanLoanAmountItems,
			financialPlanOwnFundItems,
			collaterals: right.extraCollateral ? [right.extraCollateral] : undefined,
			refinances: getRefinances(right),
		};
	}
}

export class SimulatorBorrowerToClientMapper implements MapperStrategy<Partial<Simulator.Borrower>, Partial<Client>> {
	mapToRight(dto: Partial<Simulator.Borrower>): Partial<Client> {
		return {
			id: dto?.id,
			numberOfDependents: dto?.numberOfDependents,
			birthDate: dto?.birthDate,
			firstName: dto?.firstName,
			lastName: dto?.lastName,
			phoneNumbers: dto?.phoneNumbers,
			language: dto?.language,
		};
	}

	mapToLeft(entity: Partial<Client>): Partial<Simulator.Borrower> {
		return {
			id: entity?.id,
			birthDate: entity?.birthDate,
			numberOfDependents: entity?.numberOfDependents,
			firstName: entity?.firstName,
			lastName: entity?.lastName,
			phoneNumbers: entity?.phoneNumbers,
			language: entity?.language,
		};
	}
}

function getBorrowersInfo(dto: Partial<BorrowerSimulationDto>): Simulator.Borrower[] {
	const borrowers: Simulator.Borrower[] = [];
	if (dto?.mainBorrowerIncomes?.length > 0) {
		borrowers.push({
			incomes: dto.mainBorrowerIncomes ?? [],
			liabilities: dto.mainBorrowerLiabilities ?? [],
			...dto.mainBorrowerPersonalDetails,
		});
	}

	if (dto?.coBorrowerIncomes?.length > 0) {
		borrowers.push({
			incomes: dto.coBorrowerIncomes ?? [],
			liabilities: dto.coBorrowerLiabilities ?? [],
			...dto.coBorrowerPersonalDetails,
		});
	}

	return borrowers;
}

function getRefinances(simulation: Partial<BorrowerSimulationDto>) {
	return [
		// Mortgage Refinance (refinance as additional purpose)
		...(simulation && simulation.mortgageRefinances
			? simulation.mortgageRefinances.map((refinance) => ({
					...refinance,
					tranches: refinance.tranches.map((tranche) => ({
						...tranche,
						interestRate: Math.round((tranche.interestRate / 100 + Number.EPSILON) * 100) / 100,
					})),
					borrowerIndex: 1,
				}))
			: []),
		// Nonmortgage Refinance (refinance as additional purpose)
		...(simulation && simulation.nonMortgageRefinances
			? simulation.nonMortgageRefinances.map((refinance) => ({
					valueOfTheRealty: refinance.outstandingBalance,
					liabilityType: refinance.liabilityType,
					tranches: [
						{
							outstandingBalance: refinance.outstandingBalance,
							interestRate: Math.round((refinance.interestRate / 100 + Number.EPSILON) * 100) / 100,
						},
					],
					borrowerIndex: 1,
					isCollateral: false,
				}))
			: []),
		// Refinance as main purpose
		...(simulation && simulation.tranches && simulation.tranches.length > 0
			? [
					{
						valueOfTheRealty: simulation.valueOfTheRealty,
						liabilityType: {
							id: 5,
							definition: 'mortgageImmovable',
						},
						tranches: simulation.tranches.map((tranche) => ({
							...tranche,
							interestRate: Math.round((tranche.interestRate / 100 + Number.EPSILON) * 100) / 100,
						})),
						borrowerIndex: 1,
						isCollateral: false,
					},
				]
			: []),
	];
}
