import { ChangeDetectionStrategy, Component, computed, input, signal } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UtilFormattingModule } from '@oper-client/shared/util-formatting';
import { CollapsibleModule } from '../collapsible/collapsible.module';
import { FinancialPlanCostItemGroup } from '@oper-client/shared/data-model';

@Component({
	selector: 'oper-client-simulation-report-total-project-cost',
	standalone: true,
	imports: [UtilFormattingModule, TranslateModule, CollapsibleModule],
	templateUrl: './simulation-report-total-project-cost.component.html',
	styleUrl: './simulation-report-total-project-cost.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportTotalProjectCostComponent {
	readonly $isExpanded = signal(true);
	readonly financialPlanCostGroups = input<FinancialPlanCostItemGroup[]>([]);
	readonly defaultCostGroupType = input<string>('default');
	readonly costsTotal = computed(() => (this.financialPlanCostGroups() ?? []).reduce((acc, group) => acc + group.totalCosts, 0));
}
