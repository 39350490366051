import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { UiModule } from '../../ui.module';
import { SelectOption } from '@oper-client/shared/util-data-model-transform';
import { UtilFormattingModule } from '@oper-client/shared/util-formatting';

@Component({
	selector: 'oper-client-simulation-report-mortgage-option',
	standalone: true,
	imports: [TranslateModule, UiModule, UtilFormattingModule],
	templateUrl: './simulation-report-mortgage-option.component.html',
	styleUrl: './simulation-report-mortgage-option.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationReportMortgageOptionComponent {
	readonly totalAmountToReimburse = input<number>(0);
	readonly aprc = input(0);
	readonly name = input('');
	readonly endAmount = input(0);
	readonly title = input('');
	readonly interestRate = input(0);
	readonly monthlyAmortization = input(0);
	readonly onYearsChange = output<string>();

	yearOptions: SelectOption[] = [
		{
			id: '1',
			key: '20 years',
		},
		{
			id: '2',
			key: '25 years',
		},
		{
			id: '3',
			key: '30 years',
		},
	];

	onYearsChanged(selectedId: string): void {
		this.onYearsChange.emit(selectedId);
	}
}
