import { getLoanPurposesFromDto } from '../dto/borrower-simulation.dto';
import { LoanApplicationDto } from '../dto/loan-application.dto';
import { Client } from '../models/client.model';
import { Income } from '../models/income.model';
import { Liability } from '../models/liability.model';
import { LoanRequest } from '../models/loan-request.model';
import { Offer } from '../models/offer.model';
import { MortgageRank, Realty } from '../models/realty.model';
import { Simulator } from '../models/simulator.models';
import { MapperStrategy, mapToRight, mapToLeft } from '../utils/mapper.utils';
import { SimulationToBorrowerSimulationDtoMapper } from './simulator.mappers';

export class LoanApplicationDtoToSimulationMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Simulator.Simulation>> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Simulator.Simulation> {
		const simulation = mapToLeft(dto, new SimulationToBorrowerSimulationDtoMapper());
		return {
			...simulation,
		};
	}

	mapToLeft(entity: Partial<Simulator.Simulation>): Partial<LoanApplicationDto> {
		const dto = mapToRight(entity, new SimulationToBorrowerSimulationDtoMapper());
		return {
			...dto,
		};
	}
}

export class LoanApplicationDtoToLoanRequestMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<LoanRequest>> {
	mapToRight(left: Partial<LoanApplicationDto>): Partial<LoanRequest> {
		return {
			notary: left.notary,
			ownFunds: left.ownFunds,
			purposes: getLoanPurposesFromDto(left),
			dateSubmission: left.dateSubmission,
		};
	}

	mapToLeft(right: Partial<LoanRequest>): Partial<LoanApplicationDto> {
		return {
			loanRequest: right,
			loanPurpose: right.purposes?.[0],
			ownFunds: right.ownFunds,
			notary: right.notary,
			dateSubmission: right.dateSubmission,
		};
	}
}

export class LoanApplicationDtoToOfferMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Offer>> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Offer> {
		return dto.offers?.[0];
	}

	mapToLeft(entity: Offer): Partial<LoanApplicationDto> {
		return { offers: [entity] };
	}
}

export class LoanApplicationDtoToClientsMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Client>[]> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Client>[] {
		const result: Partial<Client>[] = [];
		if (dto.mainBorrowerPersonalDetails) {
			result.push({
				...dto.clients?.[0],
				...dto.mainBorrowerPersonalDetails,
				id: dto.clients?.[0]?.id,
			});
		}

		if (dto.coBorrowerPersonalDetails) {
			result.push({
				...dto.clients?.[1],
				...dto.coBorrowerPersonalDetails,
				id: dto.clients?.[1]?.id,
			});
		}

		return result;
	}

	mapToLeft(entities: Partial<Client>[]): Partial<LoanApplicationDto> {
		const mainBorrower = entities[0];
		const coBorrower = entities[1];
		return {
			clients: entities as Client[],
			mainBorrowerPersonalDetails: mainBorrower,
			coBorrowerPersonalDetails: coBorrower,
		};
	}
}

export class LoanApplicationDtoToLiabilitiesMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Liability>[]> {
	constructor(private readonly leftState?: Partial<LoanApplicationDto>) {}

	mapToRight(left: Partial<LoanApplicationDto>): Partial<Liability>[] {
		const result: Partial<Liability>[] = [];

		if (left.mainBorrowerLiabilities) {
			result.push(...left.mainBorrowerLiabilities);
		}

		if (left.coBorrowerLiabilities) {
			result.push(...left.coBorrowerLiabilities);
		}

		return result;
	}

	mapToLeft(right: Partial<Liability>[]): Partial<LoanApplicationDto> {
		const mainBorrowerId = this.leftState?.mainBorrowerPersonalDetails?.id;
		const coBorrowerId = this.leftState?.coBorrowerPersonalDetails?.id;
		return {
			liabilities: right as Liability[],
			mainBorrowerLiabilities: right?.filter((liability) => liability?.client?.id === mainBorrowerId) as Simulator.Liability[],
			coBorrowerLiabilities: right?.filter((liability) => liability?.client?.id === coBorrowerId) as Simulator.Liability[],
		};
	}
}

export class LoanApplicationDtoToIncomesMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Income>[]> {
	constructor(private readonly leftState?: Partial<LoanApplicationDto>) {}

	mapToRight(left: Partial<LoanApplicationDto>): Partial<Income>[] {
		const result: Partial<Income>[] = [];

		if (left.mainBorrowerIncomes) {
			result.push(...left.mainBorrowerIncomes);
		}

		if (left.coBorrowerIncomes) {
			result.push(...left.coBorrowerIncomes);
		}

		return result;
	}

	mapToLeft(right: Partial<Income>[]): Partial<LoanApplicationDto> {
		const mainBorrowerId = this.leftState?.mainBorrowerPersonalDetails?.id;
		const coBorrowerId = this.leftState?.coBorrowerPersonalDetails?.id;

		return {
			incomes: right as Income[],
			mainBorrowerIncomes: right?.filter((income) => income?.client?.id === mainBorrowerId) as Simulator.Income[],
			coBorrowerIncomes: right?.filter((income) => income?.client?.id === coBorrowerId) as Simulator.Income[],
		};
	}
}

export class LoanApplicationDtoToRealtyMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Realty>> {
	mapToRight(dto: Partial<LoanApplicationDto>): Partial<Realty> {
		return {
			...dto.mainRealty,
			address: dto.address,
			price: dto.realtyPrice?.toString(),
			priceLand: dto.priceLand?.toString(),
			priceBuilding: dto.priceBuilding,
			renovations: dto.renovations,
			epcAfterRenovations: dto.epcAfterRenovations,
			epcBeforeRenovations: dto.epcBeforeRenovations,
			epcDate: dto.epcDate,
			usageTypes: dto.realtyUsageType ? [dto.realtyUsageType] : undefined,
			realtyType: dto.realtyType,
			purchaseSaleType: dto.purchaseSaleType,
			landPurchaseType: dto.landPurchaseType,
			architectFees: dto.architectFees,
			epcContractNumber: dto.epcContractNumber,
			venalValueAfter: dto.venalValueAfter,
			venalValueBefore: dto.venalValueBefore,
		};
	}

	mapToLeft(entity: Realty): Partial<LoanApplicationDto> {
		return {
			mainRealty: entity,
			realtyUsageType: entity?.usageTypes?.[0],
			realtyType: entity?.realtyType,
			address: entity?.address,
			priceBuilding: +entity?.priceBuilding,
			priceLand: +entity?.priceLand,
			realtyPrice: +entity?.price,
			renovations: entity?.renovations ?? [],
			epcAfterRenovations: entity?.epcAfterRenovations,
			epcBeforeRenovations: entity?.epcBeforeRenovations,
			epcDate: entity?.epcDate,
			region: entity?.address?.region,
			purchaseSaleType: entity?.purchaseSaleType,
			landPurchaseType: entity?.landPurchaseType,
			architectFees: entity?.architectFees,
			epcContractNumber: entity?.epcContractNumber,
			venalValueAfter: entity?.venalValueAfter,
			venalValueBefore: entity?.venalValueBefore,
		};
	}
}

export class LoanApplicationDtoToCollateralRealtyMapper implements MapperStrategy<Partial<LoanApplicationDto>, Partial<Realty>> {
	constructor(private readonly state?: Partial<LoanApplicationDto>) {}

	mapToRight(left: Partial<LoanApplicationDto>): Partial<Realty> {
		return {
			...left.collateralRealty,
			mortgageRanks: this.getUpdatedMortgageRanks(left),
			price: left.extraCollateral?.collateralAmount?.toString(),
			venalValueAfter: left.extraCollateral?.collateralAmount,
			realtyType: left.extraCollateral?.realtyType,
			usageTypes: left.extraCollateral?.realtyUsageType ? [left.extraCollateral.realtyUsageType] : undefined,
			id: left.extraCollateral?.id,
			epcContractNumber: left.extraCollateral?.epcContractNumber,
			epcBeforeRenovations: left.extraCollateral?.epcBeforeRenovations,
			epcDate: left.extraCollateral?.epcDate,
			address: left.extraCollateral?.address,
			livingPercentage: left.extraCollateral?.livingPercentage ,
		};
	}

	mapToLeft(right: Partial<Realty>): Partial<LoanApplicationDto> {
		const mortgageRank = right?.mortgageRanks?.[0];
		return {
			collateralRealty: right as Realty,
			extraCollateral: {
				id: right?.id ?? mortgageRank?.id,
				creditProvider: mortgageRank?.creditProvider,
				originalLoanAmount: mortgageRank?.amount,
				collateralAmount: right?.venalValueAfter || +(right?.price ?? 0),
				realtyType: right?.realtyType,
				realtyUsageType: right?.usageTypes?.[0],
				epcContractNumber: right?.epcContractNumber,
				epcBeforeRenovations: right?.epcBeforeRenovations,
				epcDate: right?.epcDate,
				address: right?.address,
				livingPercentage: right?.livingPercentage,
			},
		};
	}

	private getUpdatedMortgageRanks(dto: Partial<LoanApplicationDto>): MortgageRank[] {
		const mortgageRanks = dto.collateralRealty?.mortgageRanks ?? this.state?.collateralRealty?.mortgageRanks;
		const firstCollateral = mortgageRanks?.[0];
		const changes: MortgageRank = {
			...firstCollateral,
			creditProvider: dto.extraCollateral?.creditProvider ?? firstCollateral?.creditProvider,
			amount: dto.extraCollateral?.originalLoanAmount ?? firstCollateral?.amount,
		};

		return changes?.id ? mortgageRanks?.map((rank) => (rank.id === changes.id ? changes : rank)) : undefined;
	}
}
