<div class="summary">
	@if (title()) {
		<h3 class="summary__title">{{ title() | translate }}</h3>
	}
	<div class="summary__pricing">
		<span class="summary__price">{{ monthlyAmortization() | environmentCurrency }}</span>
		<span class="summary__periodicity"> / {{ 'ç.misc.duration.month.singular' | translate }}</span>
	</div>
	<p class="summary__details">{{ name() }}</p>
	<p class="summary__details">{{ ('ç.question.interestRate.label' | translate) + ': ' + (interestRate() | percent) }}</p>
	<p class="summary__details">{{ ('ç.feature.offer.product.aprc' | translate) + ': ' + (aprc() | percent) }}</p>
	<p class="summary__details">
		{{ ('ç.feature.offer.product.totalAmountToReimburse' | translate) + ': ' + (totalAmountToReimburse() | environmentCurrency) }}
	</p>
	@if (endAmount()) {
		<p class="summary__details">
			{{ ('ç.feature.offer.product.lastPayment' | translate) + ': ' + (endAmount() | environmentCurrency) }}
		</p>
	}
</div>
<div class="actions">
	<oper-client-select
		[disabled]="true"
		value="1"
		[options]="yearOptions"
		[alreadySorted]="true"
		[searchable]="false"
		[clearable]="false"
		[placeholder]="'ç.question.defaultLabel' | translate"
		(valueChange)="onYearsChanged($event)"
	>
	</oper-client-select>
</div>
